// import ErrorBanner from "../components/ErrorPage/ErrorBanner";
// import { Constants } from "../helpers/constants";
// import {configuration} from "../helpers/config";
// export default function Custom404() {
//   return (
//     <ErrorBanner
//       image={`${configuration.IMG_STATIC_URL}errorBanner.png`}
//       imageMob={`${configuration.IMG_STATIC_URL}errorMob.png`}
//       error="404"
//       desc={Constants.error.DESCRIPTION}
//       btn={Constants.error.BUTTON_TEXT}
//     />
//   );
// }

import ErrorBanner from "../components/ErrorPage/ErrorBanner";
import { Constants } from "../helpers/constants";
import {configuration} from "../helpers/config";
import { useState, useEffect } from "react";
import Header from "../components/layout/header";
import { FooterFunction } from '../utils/menu'; 
import Footer from "../components/layout/footer";


//API call function to fetch the menu data
const apicall = async () => {
  const response = await fetch(`${configuration.APP_URL_CONTENTS}/menus`);
  const data = await response.json();
  const menudata = data && data?.find((item) => item.key === "main_menu");
  return menudata;
};

export default function Custom404() {
  const [menu, setMenu] = useState([]);
  const [footer, setFooter] = useState('');
  useEffect(() => {
    apicall()
      .then((data) => {
        setMenu(data); 
      })
      .catch((error) => {
        console.error("Error fetching menu data:", error);
        setMenu([]); 
      });
  }, []); 
  useEffect(() => {
    FooterFunction()
      .then((data) => {
        setFooter(data); 
      })
      .catch((error) => {
        console.error("Error fetching Footer data:", error);
        setMenu([]); 
      });
  }, []); 
  return (
    <>
    <Header menu={menu} />
    <ErrorBanner
      image={`${configuration.IMG_STATIC_URL}errorBanner.png`}
      imageMob={`${configuration.IMG_STATIC_URL}errorMob.png`}
      error="404"
      desc={Constants.error.DESCRIPTION}
      btn={Constants.error.BUTTON_TEXT}
    />
    <Footer footer={footer}/>
    </>
  );
}
